#wkp-editor-container {
  .wkp-top-bar {
    .list-group-item {
      display: inline-block;
      padding: 0 10px;
      border: 0;
      background-color: inherit;

      &:first-child {
        flex-grow: 0;
      }

      &:last-child {
        flex-grow: 1;
      }

      &.main-item {
        flex-grow: 2;
        text-align: center;
      }

      a {
        color: #0073ff;
        text-decoration: none;

        &:hover,
        &:focus {
          color: #0073ff;
        }

        img {
          margin-right: 10px;
        }

        span {
          vertical-align: middle;
        }
      }

      button {
        margin-left: 25px;
      }
    }
  }
}
