@import '../../../_shared/variables.scss';
.wkp-no-jobs-pane {
  display: none;
}
.wkp-jobs-pane {
  position: fixed;
  z-index: 1040;
  right: 15px;
  bottom: 3%;
  background: #fff;
  border-radius: 0;
  margin-bottom: 0;
  border-color: transparent !important;

  h4 {
    font-weight: normal;
  }

  .wkp-pane-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: none;
    background-color: #030303;
    height: 40px;
    color: #fff;
    padding-right: 5px;
  }

  .wkp-failed-jobs-title {
    background: #e61e3c;
  }

  .panel-body {
    padding: 0px;
    padding-left: 15px;
  }
  .panel-body-minimize {
    display: none;
  }
  .wkp-window-action-btn {
    background: transparent;
    border: none;
  }
  .wkp-window-btn-minimize {
    font-size: 18px;
    margin-left: 40px;
  }
  .wkp-window-btn-close {
    font-size: 24px;
  }
  .wkp-jobs-list {
    height: 195px;
    max-height: 240px;
    max-width: 320px;
    overflow: hidden;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    padding-top: 10px;
  }

  .wkp-jobs-list-expand {
    height: 180px;
  }
  .wkp-job-item {
    cursor: default;
    padding-right: 5px;
    align-items: center;
    text-align: start;
    min-width: 280px;
    line-height: 1.6;

    img {
      margin: 0px 2px 2px 0px;
    }
    span {
      max-width: 230px;
    }
    a {
      cursor: pointer;
    }
  }

  .wkp-job-item-name {
    display: inline-flex;
    width: 90%;
    b {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  .wkp-job-item-icon {
    font-size: 20px;
    float: right;
    position: relative;
    bottom: 15%;
  }
  .wkp-job-item-success-icon {
    color: #00a223;
  }
  .wkp-job-item-fail-icon {
    color: #e61e3c;
  }
  .wkp-job-item-error-message {
    color: #e61e3c;
  }

  .progress-spinner {
    -webkit-animation-name: spin;
    -webkit-animation-duration: 2000ms;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    -moz-animation-name: spin;
    -moz-animation-duration: 2000ms;
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: linear;
    -ms-animation-name: spin;
    -ms-animation-duration: 2000ms;
    -ms-animation-iteration-count: infinite;
    -ms-animation-timing-function: linear;
    animation-name: spin;
    animation-duration: 2000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
  @-ms-keyframes spin {
    from {
      -ms-transform: rotate(0deg);
    }
    to {
      -ms-transform: rotate(360deg);
    }
  }

  @-moz-keyframes spin {
    from {
      -moz-transform: rotate(0deg);
    }
    to {
      -moz-transform: rotate(360deg);
    }
  }

  @-webkit-keyframes spin {
    from {
      -webkit-transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
}
