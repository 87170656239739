.wkp-preview,
.wkp-preview-no-palette {
  position: absolute;
  right: 477px + 2 * 8px; // inspector width + 2 gaps
  left: 93px + 8px; // stencil width
  bottom: 0px;
  background: white;
  box-shadow: 0px 0px 0px 0 rgba(0, 0, 0, 0.15);
  height: 242px;
  z-index: 2;

  h4.wkp-preview-title {
    background-color: #d7d7d7;
    padding: 10px 20px;
    margin: 0;
    font-weight: bold;

    .title-reset-view {
      color: #1f88ff;
      font-weight: bold;
      font-size: 16px;
      cursor: pointer;
      margin-left: 5px;
    }
  }

  .wkp-preview-ui {
    display: flex;
  }

  .wkp-preview-output-toggle {
    flex: 1;
    background-color: #f5f5f5;
    height: 242px;
    vertical-align: top;
    width: 34px;

    li {
      width: 34px;
      height: 34px;
    }

    li:hover {
      background-color: #ddd;
    }

    .nav-item-active {
      background-color: #ddd;
    }

    img {
      padding: 9px 7px;
    }
  }

  .wkp-preview-body {
    flex: 1;
    height: fit-content;
    .wkp-spinner-wrapper {
      height: 200px;
    }

    // footer control elements
    .row {
      display: none;
    }

    .bt-grid {
      overflow-x: auto;
      .wj-cell {
        border-left: 1px solid rgb(216, 216, 216) !important;
        border-bottom: 1px solid rgb(216, 216, 216) !important;

        &:first-child {
          padding-left: 20px;
        }

        &:last-child {
          padding-right: 20px;
          border-right: 1px solid rgb(216, 216, 216) !important;
        }

        &:not(.wj-header).wkp-shaded {
          background-color: #f5f5f5 !important;
        }

        &.wj-alt {
          &:not(.wj-header).wkp-shaded {
            background-color: #f5f5f5 !important;
          }
        }
      }
    }
  }

  .wkp-preview-grid-navBar {
    width: 100%;
    height: 100%;
    overflow: auto;
    display: flex;
    > nav {
      flex: 0 0 40px;
    }
    > div {
      flex: 1;
      background-color: white;
    }
  }

  #df-preview-bar {
    padding-top: 1.5rem;
    background-color: #d7d7d7;
    width: 40px;
    height: 75vh;

    img {
      height: 20px;
    }

    .nav {
      list-style: none;
      text-align: center;
      width: 40px;

      li {
        padding: 5px 0px;
      }
      li:first-child {
        margin-top: -15px;
      }

      li:hover,
      li:focus {
        background-color: rgb(187, 187, 187);
        cursor: pointer;
      }

      .nav-item.active {
        background-color: rgb(148, 148, 148);
      }
    }
  }
}

.wkp-preview-expand,
.wkp-preview-expand-no-palette {
  @extend .wkp-preview;
  height: 80vh;
}

.wkp-preview-minimize,
.wkp-preview-minimize-no-palette {
  @extend .wkp-preview;
  height: 5vh;
  bottom: auto;
}

.wkp-preview-pane-arrow {
  transition: 0.5s ease-in-out;
}
.wkp-preview-pane-arrow.active {
  transform: rotate(180deg);
}

.wkp-preview-title-pointer {
  cursor: pointer;
}

.wkp-preview-pane-toolTip {
  display: flex;
  position: relative;
  float: right;
}

.wkp-preview-pane-tooltiptext {
  visibility: hidden;
  width: 80px;
  background-color: #fff;
  color: #000;
  text-align: center;
  //border-radius: 6px;
  padding: 5px 0;
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  font-size: 10px;
  font-weight: normal;
  border-style: solid;
  border-width: thin;
  bottom: 200%;
  left: 50%;
  margin-left: -40px;
}

.wkp-preview-pagination-button {
  background: #d7d7d7;
  border: none;
  font-weight: normal;

  &:hover {
    background: #a5a5a5;
  }

  &:active {
    background: #8c8c8c;
  }
}

span.vertical-line {
  border-left: 1px solid #a5a5a5;
  height: 100%;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: -10px;
  margin-bottom: -10px;
}

.wkp-preview-no-palette {
  left: 0px;
}

.wkp-preview-expand-no-palette,
.wkp-preview-minimize-no-palette {
  left: 0px;
}

.wkp-preview-button {
  height: 24px;
  width: 24px;
  background: none;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background: #a5a5a5;
  }

  &:active {
    background: #8c8c8c;
  }
}
