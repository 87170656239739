#wkp-side-bar {
  .wkp-right-pane {
    .wkp-topic-header {
      padding-left: 12px;

      .bbui-button {
        margin-right: 10px;
        padding: 0;
      }

      .wkp-back-topic-selection {
        &:hover {
          background-color: inherit;
          border: none;
        }

        &:focus {
          box-shadow: none;
        }
      }

      span {
        vertical-align: middle;
      }
    }

    .wkp-topic-selected {
      margin-right: -20px;
      padding-right: 12px;

      h4 {
        font-weight: bold;
      }

      span {
        display: block;
        margin-bottom: 5px;
      }
    }
  }
}
