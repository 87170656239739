.wkp-stencil-container {
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0;
  width: 100px;
  z-index: 2;

  .joint-stencil.joint-theme-default {
    border: none;
  }

  .joint-stencil.joint-theme-default,
  .joint-paper.joint-theme-default > svg {
    background-color: #f5f5f5;
  }

  // Make scrollbars thin
  .content {
    scrollbar-width: thin;

    &::-webkit-scrollbar {
      width: 9px;
    }

    &::-webkit-scrollbar-thumb {
      background: #d8d8d8;
      border-radius: 9px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: darken(#d8d8d8, 15);
    }
  }
}
