.wkp-data-flows-container {
  padding: 30px;
  height: calc(100vh - 69px);
  display: flex;

  @media only screen and (max-height: 900px) {
    padding: 15px;
  }

  .wkp-data-flows-content {
    flex: 1;
    min-width: 400px;
  }
}

.wkp-linked-workpapers-modal {
  .bbui-dialog-body {
    overflow-y: unset;
  }
}
