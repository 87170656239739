.navbar-research-icon {
  position: relative;
}

.navbar-tax-research {
  &.modal-active {
    background-color: #444;
  }
  .toggle-button {
    margin-right: 10px;
  }

  .research-ai-btn {
    display: flex;
    align-items: center;
  }

  .upgrade-button {
    outline: none;
    border: none;
    border-radius: 6px;
    background-color: #00b0e7;
    font-size: 10px;
    font-weight: 700;
    height: 18px;
    color: black;
    margin-right: 5px;
  }
}

#scroll-container {
  li > a.link {
    color: #000;
    text-decoration: underline;
  }
}

.research-dropdown {
  position: absolute;
  width: 540px;
  background: #fff;
  z-index: 99;
  right: 0;
  box-shadow:
    0 1px 1px rgba(0, 0, 0, 0.12),
    0 2px 2px rgba(0, 0, 0, 0.12),
    0 4px 4px rgba(0, 0, 0, 0.12),
    0 8px 8px rgba(0, 0, 0, 0.12),
    0 16px 16px rgba(0, 0, 0, 0.12);

  p {
    all: unset;
  }
  .research-header {
    height: 36px;
    background-color: #267ac3;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;

    p {
      color: white;
      font-weight: 200;
      font-size: 12px;
    }

    .research-icon {
      img {
        height: auto;
      }
    }

    .toggle-ai {
      background-color: white;
      border-radius: 45px;
      font-size: 12px;
      font-weight: 400;
      display: flex;
      gap: 6px;
      justify-content: space-between;
      align-items: center;
      color: #267ac3;
      border: 0;
      outline: none;
      padding: 4px 12px;
    }

    .dropdown-button {
      all: unset;
      cursor: pointer;
    }
  }

  .research-body {
    padding: 0 15px 15px;

    .title-box {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .title {
        margin: 10px 0;
        font-size: 16px;
        font-weight: 700;
      }
    }

    .search-box {
      display: flex;

      .bt-form-group-wrapper {
        flex: 1;
      }

      .search-icon {
        border: 1px solid #000;
        background-color: transparent;
        font-size: 16px;
        display: grid;
        place-items: center;
        color: black;
        &:disabled {
          background: lightgray;
        }
      }
    }

    .spinner-container {
      display: grid;
      place-items: center;
      padding: 50px;
    }

    .scroll-container {
      overflow: auto;
      max-height: 520px;
      margin-top: 20px;

      .title-md {
        margin-top: 0;
        margin-bottom: 10px;
        font-weight: 600;
      }

      .summary-content {
        font-size: 14px;
        margin-top: 2px;
      }

      .research-summary .summary-disclaimer {
        font-size: 12px;
        font-style: italic;
        margin: 10px 0;
      }
    }

    .error-container {
      margin-top: 30px;
    }
  }

  .btn-text {
    all: unset;
    display: block;
    font-weight: 600;
    font-size: 14px;
    color: #267ac3;
    margin: 10px 0;
    cursor: pointer;
  }
}

.disclaimer-ai-icon {
  padding: 4px;
  background-color: #267ac3;
}

.subscription-container {
  line-height: 1.4;
  color: #000;
  overflow: hidden;
  font-size: 16px;

  .grid {
    display: grid;
    grid-template-columns: 0.4fr 0.6fr;
    gap: 26px;
    margin-top: 26px;

    .wkp-annotations > ol:not([start]) {
      counter-reset: item;
    }
    .wkp-annotations > ol {
      margin-top: 10%;
      height: 75%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .wkp-annotations > ol > li {
      display: block;
      font-family: inherit;
      margin: 8px 0;
      margin-bottom: 20px;
      line-height: 1.4;
    }
    .wkp-annotations > ol > li::before {
      float: left;
      content: counter(item);
      counter-increment: item;
      font-family: sans-serif;
      text-align: center;
      width: 30px;
      height: 30px;
      line-height: 29px;
      border-radius: 20px;
      background: #fedf00;
      font-size: 18px;
      margin: 0 10px 0 -40px;
    }

    img {
      width: 100%;
    }
  }
}
