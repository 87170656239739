.wkp-find-and-replace-fields-selectors {
  .wkp-find-and-replace-field-row {
    $gap: 5px;

    display: flex;
    margin: 1 (-1 * $gap) $gap;
    margin-top: 10px;

    > .bt-form-group-wrapper {
      width: 45%;
      align-self: flex-end;
      flex-grow: 1;
      justify-content: center;
      margin: 0;
      padding: 0 $gap;

      &:last-child {
        flex-grow: 0;
        flex-shrink: 1;
        width: 50%;
      }
    }
  }
  .wkp-find-and-replace-error {
    margin-top: 10px;
    text-align: right;
    color: red;
  }
}
