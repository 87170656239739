.wkp-fields-selector {
  .wkp-field-row {
    $gap: 5px;

    display: flex;
    margin: 0 (-1 * $gap) $gap;

    > .bt-form-group-wrapper {
      width: 45%;
      align-self: flex-end;
      flex-grow: 1;
      justify-content: center;
      margin: 0;
      padding: 0 $gap;

      &:last-child {
        flex-grow: 0;
        flex-shrink: 1;
        width: 10%;
      }
    }

    .wkp-delete-btn {
      height: 40px;
    }
  }
}
