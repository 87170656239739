.wkp-imported-source-files-modal {
  .bbui-overlay-container {
    width: 900px;
    .bbui-dialog-body {
      width: 860px;
      overflow-y: unset;
    }
    .bbui-dialog-content {
      width: 900px;
    }
  }
}
