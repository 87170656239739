.rollforward-modal {
  .body-modal-height {
    height: 300px;
  }

  .dialog-subtitle {
    font-weight: 600;
    padding-top: 15px;
  }

  .current-tax-period {
    padding-top: 5px;
  }

  .new-tax-period {
    padding-top: 5px;
  }

  button {
    margin: 2px;
  }
}
