.wkp-data-flows-list-section {
  h2 {
    margin: 0 0 10px;
    font-weight: normal;
    font-size: 24px;
  }

  .wkp-data-flows-list-container {
    background-color: #fff;
    padding-bottom: 10px;

    .wkp-data-flows-grid-actions {
      font-size: 15px;

      @media only screen and (max-height: 900px) {
        padding-top: 4px;
        padding-bottom: 0;
      }
    }

    .bt-grid {
      background-color: #fff;
      height: calc(100vh - 251px);

      @media only screen and (max-height: 900px) {
        height: calc(100vh - 194px);
      }

      .wkp-data-flow-table-icon {
        margin: -4px 10px 0 0;
      }
    }

    .bt-grid-filter-row {
      border-bottom: 1.8px solid black;
    }
    .bt-status-color-dot {
      display: inline-block;
      height: 10px;
      width: 10px;
      border-radius: 50%;
      margin-right: 5px;
    }
  }
  .wj-content {
    height: calc(75vh);
    @media only screen and (max-height: 900px) {
      height: calc(100vh - 194px);
    }
  }
  .bt-no-rows {
    margin-top: calc(-50vh + 100px);
  }
}

.user-initials {
  display: inline-block;
  width: 30px;
}

.user-initials-inner {
  display: inline-block;
  border-radius: 10px;
  min-width: 22px;
  padding: 4px;
  text-align: center;
  background-color: #cdcdcd;
  font-size: 10px;
  color: #000;
  cursor: default;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.df-status-tag {
  display: inline-block;
  border-radius: 5px;
  padding: 1px 8px;
  margin: 0px 15px;
  cursor: default;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.df-status-unpublished {
  background-color: #ffe2bd;
  color: #000;
}

.wkp-data-flow-lock-icon {
  margin-left: 3px;
  margin-bottom: 1px;
  color: #646464;
  font-size: 12px;
}
