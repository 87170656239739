#wkp-side-bar {
  .wkp-right-pane {
    .wkp-topic-selected {
      .panel-default {
        border: none;
        box-shadow: none;
      }

      .panel-heading {
        background-color: #f9f9f9;
        background-image: none;
        padding: 0;
      }
      .panel-title {
        font-size: 18px;

        a {
          text-decoration: none;
          display: block;
          padding: 10px;

          &:hover {
            background-color: #f2f2f2;
          }

          svg {
            min-width: 14px;
            margin-right: 10px;
          }

          span {
            display: inline;
          }
        }
      }

      .panel-body > div:first-child h4 {
        margin-top: 3px;
      }

      .panel-group .panel + .panel {
        margin-top: 0;
      }

      .panel-default > .panel-heading + .panel-collapse > .panel-body {
        background-color: #f9f9f9;
        border-top: 0;
      }
    }
  }
}
