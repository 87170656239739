.wkp-sftp-pick-file-dialog {
  .wkp-filename-input-group {
    display: flex !important;
  }

  .wj-header-inner {
    width: auto !important;
  }

  .bt-currently-sorted .bt-grid-caret-up,
  .bt-currently-sorted .bt-grid-caret-down {
    margin-left: 8px;
  }

  .bt-grid-sorting-icons {
    align-items: flex-start !important;
    padding-left: 8px;
  }

  .bbui-dialog-content-container {
    max-width: 900px !important;
    width: 900px !important;
  }

  .wkp-dialog-refresh {
    margin-right: 5px;
  }

  .bbui-dialog-body {
    overflow-x: hidden;
  }

  /* Dialog breadcrumb secction*/
  .wkp-dialog-path {
    display: flex;
    justify-content: space-between;

    .wkp-dialog-breadcrumb .bt-button-link:hover:not(:disabled),
    .wkp-dialog-breadcrumb .bt-button-link {
      margin-left: 6px;
      margin-right: 6px;
      text-decoration: none !important;
      color: #232323 !important;
    }

    .wkp-dialog-breadcrumb .bbui-icon {
      font-size: 10px;
      padding: 2px 0px;
    }
  }

  /* Grid icons and link color */
  .wj-cell:not(.wj-header):not(.wj-header-alt) .bt-button-link {
    text-decoration: none;
    color: #232323;
  }

  .sftp-file-cell {
    cursor: pointer;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 100%;
    display: inline-block;
    text-align: left;
    padding-right: 7px;

    .bbui-icon {
      margin-right: 6px;
    }
  }

  .sftp-invalid-file-cell {
    color: #9d9d9d;
    .bbui-icon {
      margin-right: 6px;
    }
  }

  .bt-empty-state-grid {
    h4 {
      margin-bottom: 20px !important;
    }
  }

  .wj-row {
    cursor: pointer;
  }

  .wkp-file-excel-icon {
    vertical-align: top;
    margin-right: 5px;
    padding-top: 2px;
  }

  .wkp-folder-icon-class {
    vertical-align: top;
    margin-right: 5px;
    padding-top: 2px;
  }
}
