.wkp-edit-connection-panel {
  overflow-y: auto;
}

.wkp-edit-footer-buttons {
  margin-top: 20px;

  .col-xs-6 {
    .bbui-button {
      width: 100%;
    }

    &:first-child {
      padding-right: 5px;
    }

    &:last-child {
      padding-left: 5px;
    }
  }
}

.wkp-connection-action-buttons {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  button {
    margin-left: 12px;
  }
}

// Override BTAlert styles for sidebar
.bt-alert .content {
  margin: 0 16px !important;
}
