.tpscreenshot {
  width: 100%;
}

.workpaper-send-to-tp-modal {
  #workpaper-sheets {
    width: 100%;
  }

  .bbui-feedback {
    margin-top: 20px;
  }
}

.wkp-tp-modal-alert-message {
  max-width: 100%;
}
