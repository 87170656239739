#wkp-page-container {
  position: relative;
  height: 100%;

  main {
    margin-left: 40px;
    width: calc(100vw - 40px);
    position: absolute;
  }

  main.editor {
    margin-left: 0px;
    width: 100%;
  }

  .navbar-brand img {
    height: 44px;
  }
}
