.text-filter-area {
  display: grid;
  position: relative;
  width: 100%;
}

.text-filter-area span {
  position: absolute;
  bottom: 10px;
  right: 10px;
}
