.wkp-recent-activity {
  background-color: white;
  padding: 16px;
  width: 300px;
  margin-left: 30px;
  display: flex;
  flex-direction: column;

  p {
    margin: 10px 0;
  }

  .wkp-recent-activity-title {
    font-size: 16px;
    font-weight: bold;
    margin: 0;
  }

  .wkp-recent-activity-items {
    overflow: auto;
    max-height: 100%;
    flex: 1;
  }
}
