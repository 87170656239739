@import '~@clientio/rappid/rappid.css';

.wkp-data-flows-editor-page {
  .wkp-data-flow-editor {
    position: relative;
    height: calc(100vh - 118px);
  }
}

.joint-text-editor.joint-theme-default .caret {
  display: block;
  border: none;
}

.bold-text {
  font-weight: bold;
}

.wkp-multimerge-inspector-footer {
  padding: 20px;

  .content {
    color: #fff !important;
  }

  .collapse:last-child {
    .alert {
      margin-bottom: 0;
    }
  }
}

.wkp-output-element-inspector .wkp-fa-export-output-element-inspector {
  margin-top: 20px;
  margin-bottom: 20px;
}

#wkp-df-side-bar {
  position: absolute;
  height: 100%;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  bottom: 0;
  right: 0;
  z-index: 2;

  .btn-link:focus {
    outline: none;
  }

  .wkp-df-right-nav {
    position: relative;
    background: #808080;
    height: 100%;
    width: 40px;

    .nav > li {
      display: flex;
      justify-content: center;
      overflow: hidden;
    }

    .nav > li > button {
      color: white;
      padding: 9px 12px;

      &:hover {
        background-color: #666;
      }
    }

    .nav > li.active > button {
      background-color: #0073ff;
    }
  }

  .wkp-df-right-panel {
    position: absolute;
    top: 0px;
    right: 40px;
    bottom: 0px;
    width: 444px;
    background: white;
    display: flex;
    flex-direction: column;
    z-index: 2;
    box-shadow: 0px 0px 0px 0 rgba(0, 0, 0, 0.15);

    .wkp-df-inspector-title {
      background-color: #d7d7d7;
      font-weight: bold;

      .wkp-df-panel-header {
        display: flex;
        padding: 10px 20px;
        font-size: 18px;

        .wkp-df-right-panel-name {
          height: 20px;
          display: flex;
          align-items: center;
        }
        .wkp-df-right-panel-close {
          margin-left: auto;
          cursor: pointer;
          display: flex;
          height: 20px;
          align-items: center;
          border: none;
          background: none;
        }
      }
    }

    .wkp-df-panel-content {
      padding: 14px 20px 0;

      .bbui-feedback {
        position: absolute;
        bottom: 100px;
        width: 88%;
        padding: 14px 12px 14px 0px;
      }
    }
  }
}

.df-pagination-page {
  display: flex;
  float: right;
  margin: 5px;
}

.df-pagination-line {
  background-color: black;
  height: 1px;
  border: 0px;
  margin: 5px 0px;
}
