.wkp-home-container {
  background-color: #ffffff;
  height: calc(100vh - 42px);

  .tax-period-container {
    display: flex;
    justify-content: flex-end;
  }
  .wkp-tax-period-select-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    height: 100px;
    padding: 20px 20px 0px 0px;
    width: fit-content;

    .wkp-tax-period-select-label {
      font-size: 16px;
      align-self: flex-start;
    }
  }
}
