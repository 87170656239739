@import './components/_shared/variables.scss';

body {
  background-color: #f9f9f9;
  overflow: hidden;
  height: 100vh;
  margin: 0px;

  #root {
    width: 100%;
    height: 100%;

    [class*='ThemeProvider'],
    #jasper_provider {
      height: 100%;
    }
  }
}

.wkp-load-general-error {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 121px);
  font-size: 24px;
}
