#wkp-side-bar {
  .wkp-right-pane {
    .wkp-named-version-switch {
      margin-bottom: 10px;

      .bbui-switch span {
        margin-left: 0;
      }
    }

    .wkp-history-list {
      display: flex;
      flex-direction: column;
      margin-right: -20px;
      padding-right: 12px;
      overflow-y: auto;

      .list-group-item {
        padding: 10px 20px;
        border: 0;
        background-color: inherit;

        .bt-action-menu {
          border: 0;
        }

        &:hover {
          background-color: #eee;
          text-shadow: none;
          background-image: none;
          color: #333;
        }

        .wkp-history-item-main-row {
          display: flex;
          align-items: center;
          min-height: 40px;

          .wkp-history-item-main {
            margin-right: 8px;
            flex-grow: 2;

            text-overflow: ellipsis;
            white-space: nowrap;
            max-width: 335px;
            overflow: hidden;
          }

          .wkp-history-item-clickable {
            cursor: pointer;
          }

          .wkp-history-item-selected {
            font-weight: bold;
          }

          .wkp-history-initials {
            display: inline-block;
            width: 30px;
            height: 30px;
            text-align: center;
            padding-top: 6px;
            border-radius: 50%;
            color: white;
            margin-right: 10px;
          }

          .wkp-initials-color {
            background-color: #7d109b;
          }
        }

        .wkp-history-item-origin-legend {
          margin-left: 40px;
          max-width: 335px;
          display: flex;

          img {
            width: 20px;
            height: 20px;
            flex-grow: 1;
          }

          span {
            margin-left: 8px;
            flex-grow: 2;
          }
        }
      }
    }
  }
}
