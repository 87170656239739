.wkp-input-element-inspector {
  .wkp-sample-file-upload {
    h4 {
      text-align: center;
      margin: 32px 0 22px;
    }

    .wkp-sample-file-dropzone {
      height: 210px;
    }
  }

  .wkp-source-file-name {
    font-weight: 700;
    margin-bottom: 4px;
  }

  .wkp-upload-file-status {
    display: flex;
    justify-content: space-between;
    text-align: center;
    align-items: center;
    padding: 5px 5px 5px 15px;
    margin: 15px 0 15px 0;
    background-color: #ebebeb;
    height: 32px;
    border-radius: 16px;
  }

  .wkp-upload-file-elements {
    display: flex;
    align-items: center;
  }

  .wkp-progress-bar-btn-close {
    font-size: 20px;
    background: transparent;
    border: none;
    display: flex;
    align-items: center;
  }

  .wkp-upload-file-text {
    margin: 30px 0 10px 0;
    text-align: center;
  }

  .wkp-select-data-within-file {
    width: 100%;
  }

  .wkp-upload-file-spinner-wrapper {
    display: flex;
    align-items: center;
    padding-right: 14px;
  }

  .wkp-upload-file-success-icon {
    color: #00a223;
    font-size: 20px;
    display: flex;
    align-items: center;
    padding-right: 8px;
  }

  .wkp-input-sheet-details {
    font-size: 12px;
    color: #666;
  }

  .wkp-input-upload-file-row {
    margin-top: 15px;
    font-weight: bold;
  }

  .wkp-input-upload-file-col {
    margin-top: 10px;
    width: 100%;
  }

  .wkp-input-upload-file-but {
    @extend .wkp-input-upload-file-col;
    margin-right: 10px;
  }
  .wkp-df-inputBlock-buttons {
    margin-top: 15px;
    width: 100%;
    text-transform: none;
  }

  .wkp-input-fa-go-back-link {
    margin-top: 15px;
  }

  .wkp-input-fa-configure-textnote {
    margin-top: 5px;
    color: grey;
  }

  .wkp-input-fa-configure-textnote-retrieving {
    @extend .wkp-input-fa-configure-textnote;
    margin-top: 10px;
    text-align: center;
  }

  .wkp-input-fa-title {
    font-weight: bold;
  }
  .wkp-input-button-row {
    display: flex;
  }
}
