.wkp-create-workpapers-buttons {
  display: flex;
  flex-wrap: wrap;
  column-gap: 10px;
  padding: 5px 15px 20px 5px;
  margin: 0;

  .status-tile-button {
    background-color: inherit;
    width: fit-content;
    min-width: 125px;
    height: 90px;
    margin: 0px;
    padding: 15px;
    border: none;
    box-shadow: none;

    .status-color-dot {
      display: inline-block;
      height: 10px;
      width: 10px;
      background-color: #000;
      border-radius: 50%;
      margin-right: 5px;
    }

    &:hover {
      background-color: #f5f5f5;
      box-shadow: none;
    }

    p {
      font-size: 20px;
    }
  }

  @media only screen and (max-height: 900px) {
    padding: 15px;
  }
}
