@import '../../../_shared/variables.scss';

$drop-accepted-border: 4px dotted $btas-crescent-color;
$drop-accepted-back-color: rgba(0, 115, 255, 0.08);

.wkp-deleted-wkp-section {
  .wkp-grid-container {
    position: relative;
    background-color: #fff;
    height: fit-content;
    margin-bottom: 35px;
    margin-left: 12px;
    .bt-customize-grid {
      height: calc(100vh - 225px);

      .wkp-deleted-wkp {
        display: flex;
        align-items: baseline;

        img {
          margin-right: 10px;
        }
      }
    }
  }

  &:not(.wkp-empty) {
    & > h2 {
      display: none;
      margin-top: 0;
      margin-bottom: 20px;
      font-weight: normal;
    }
  }

  &.wkp-empty {
    text-align: center;
    margin-top: 20px;
    width: calc(100vw - 110px);
    cursor: default;

    h2 {
      margin-bottom: 50px;
      font-size: 24px;
    }
    .wkp-grid-container {
      border: 1px solid #ddd;
      padding: 50px;
      text-align: center;
    }
  }
}
