.wkp-data-flow-canvas {
  z-index: 1;

  .paper-scroller-background {
    background-color: #f5f5f5;
  }

  .paper-scroller-background > .joint-paper.joint-theme-default {
    background-color: #f5f5f5 !important;
  }

  text[type='annotation'] {
    tspan::selection {
      background: lightblue;
    }
  }

  ::-webkit-scrollbar-track {
    background-color: #f5f5f5 !important;
  }
  ::-webkit-scrollbar-corner {
    background-color: #f5f5f5 !important;
  }
}
.wkp-data-flow-canvas-preview {
  height: calc(100vh - 360px);
}
.wkp-data-flow-canvas-no-preview {
  height: calc(100vh - 118px);
}
.wkp-data-flow-editor:has(div.wkp-data-flow-canvas-preview):has(.wkp-preview-minimize) div.wkp-data-flow-canvas-preview,
.wkp-data-flow-editor:has(div.wkp-data-flow-canvas-preview):has(.wkp-preview-minimize-no-palette)
  div.wkp-data-flow-canvas-preview {
  height: calc(100vh - 161px);
}
.wkp-data-flow-canvas-inspector {
  width: calc(100vw - 532px);
}
.wkp-data-flow-canvas-no-inspector {
  width: 100%;
}
.wkp-data-flow-canvas-no-inspector-sidebar {
  width: calc(100vw - 82px);
}

.selection-wrapper {
  display: none;
}

.joint-selection.joint-theme-default .selection-box {
  border: none !important;
}

.selection-wrapper {
  display: none;
}

.joint-selection.joint-theme-default .selection-box {
  border: none !important;
}

.joint-tooltip.joint-theme-default.rendered {
  max-width: 272px;
  background-color: white;

  &.left {
    .tooltip-arrow {
      border: none;
      width: 10px;
      height: 10px;
      left: -5px;
      transform: rotate(-45deg);
      background: #ffffff;
      border-left: 1px solid #666666;
      border-top: 1px solid #666666;
    }

    .tooltip-arrow-mask {
      display: none;
    }

    .tooltip-content {
      border: 1px solid #666666;
    }

    img {
      display: block;
      margin-left: auto;
      margin-right: auto;
      padding-top: 15px;
    }
  }
}
