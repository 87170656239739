.wkp-navigator-container,
.wkp-navigator-container-panel {
  position: absolute;
  bottom: 25px;
  right: 25px;
  z-index: 1;

  .joint-navigator {
    .joint-paper-background,
    .joint-paper-grid {
      background-color: #fff;
    }

    &.joint-theme-default {
      border: none;
      box-shadow: -1px 2px 12px 0 rgba(0, 0, 0, 0.15);
      background-color: #fff;

      & > .joint-paper {
        box-shadow: none;

        &.joint-theme-default {
          background-color: #fff;
        }
      }
    }
  }

  .current-view,
  .current-view-control {
    border-color: #0073ff !important;
  }
}

.wkp-navigator-container-panel {
  right: 65px;
}
