#wkp-side-bar {
  position: fixed;
  height: 100%;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  bottom: 0;
  right: 0;
  z-index: 2;

  .btn-link:focus {
    outline: none;
  }

  /* RIGHT NAVBAR */
  .wkp-right-nav {
    position: relative;
    background: #808080;
    height: 100%;
    width: 42px;
    z-index: 2;

    .nav > li > button {
      color: white;
      padding: 12px 9px;

      &:hover,
      &:focus {
        background-color: #666;
      }
    }

    .nav > li.active > button {
      background-color: #0073ff;
    }
  }

  /* RIGHT PANE */
  .wkp-right-pane {
    display: flex;
    flex-direction: column;
    position: relative;
    height: 100%;
    width: 500px;
    border: 1px solid #808080;
    padding: 30px 30px 15px 30px;
    background-color: #f9f9f9;
    z-index: 2;

    .wkp-right-pane-header {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      margin-bottom: 20px;

      h2 {
        font-size: 22px;
        font-weight: normal;
        margin: 0;
      }

      .wkp-pane-close {
        padding: 5px;
        max-height: 30px;

        &:hover {
          background-color: #f2f2f2;
        }

        img {
          width: 18px;
        }
      }
    }

    .wkp-right-pane-empty-state {
      justify-content: flex-start;
      flex: 0;
      padding-bottom: 0;
    }
  }
}
