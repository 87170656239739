.wkp-right-pane {
  .wkp-meta-form {
    height: 80%;
    overflow-y: auto !important;
    overflow-x: hidden;
    margin-right: -20px;
    padding-right: 12px;

    .col-xs-6 {
      &:first-child {
        padding-right: 5px;
      }

      &:last-child {
        padding-left: 5px;
      }

      .bbui-button {
        width: 100%;
      }
    }

    .row {
      margin-bottom: 20px;
    }

    .wkp-meta-form-fields {
      padding-bottom: 20px;
    }
  }
}
