@import '../../../_shared/variables.scss';

$drop-accepted-border: 4px dotted $btas-crescent-color;
$drop-rejected-border: 4px dotted #ea2839;
$drop-accepted-back-color: rgba(0, 115, 255, 0.08);
$drop-rejected-back-color: rgba(#ea2839, 0.08);

.wkp-saved-wkp-section {
  .wkp-grid-container {
    position: relative;
    background-color: #fff;
    height: fit-content;
    margin-bottom: 35px;
    margin-left: 12px;
    .bt-customize-grid {
      height: calc(100vh - 225px);

      .wkp-saved-wkp {
        display: flex;
        align-items: baseline;

        img {
          margin-right: 10px;
        }
      }
    }
  }

  &:not(.wkp-empty) {
    & > h2 {
      display: none;
      margin-top: 0;
      margin-bottom: 20px;
      font-weight: normal;
    }

    &.wkp-drag {
      .wkp-drop-zone {
        border: $drop-accepted-border;
        background-color: $drop-accepted-back-color;
      }
    }
  }

  &.wkp-empty {
    text-align: center;
    margin-top: 20px;
    width: calc(100vw - 110px);
    cursor: default;

    h2 {
      margin-bottom: 50px;
      font-size: 24px;
    }
    .wkp-grid-container {
      border: 1px solid #ddd;
      padding: 50px;
      text-align: center;
    }

    .wkp-empty-state-container {
      border: 4px dotted #000;
      height: calc(100vh - 475px);
      min-height: 150px;

      h2 {
        margin-top: 100px;
        font-size: 30px;
      }

      h4 {
        font-weight: normal;
        margin-bottom: 100px;
      }

      @media screen and (max-height: 900px) {
        h2 {
          margin-top: 50px;
          margin-bottom: 30px;
        }

        h4 {
          margin-bottom: 30px;
        }
      }

      img {
        margin-right: 30px;
      }

      #wkp-browse-text {
        cursor: pointer;
        color: #000;
        text-decoration: none;
        border-bottom: 2px dotted #000;
      }
    }

    &.wkp-drag {
      .wkp-empty-state-container {
        margin: 30px;
        border: $drop-accepted-border;
        background-color: $drop-accepted-back-color;
      }
    }
  }
}
