.ai-generated-button {
  all: unset;
  border-radius: 4px;
  background: #000;
  color: white;
  font-size: 12px;
  font-weight: 500;
  padding: 4px 6px;
  cursor: pointer;
  display: inline-flex;
  gap: 4px;
}

.ai-generated-button:hover {
  text-decoration: none;
  color: white;
  background: #373737;
}

.ai-generated-button:focus {
  outline: solid 1px #0d9cd9;
  text-decoration: none;
  color: white;
}

.ai-generated-button.disabled {
  color: #a5a5a5;
  background: #cdcdcd;
  pointer-events: none;
  cursor: default;
}

.ai-generated-icon {
  height: 16px !important;
}
