.tippy-tooltip:has(.signOff-popover) {
  color: white !important;
  font-size: 13px;
  background-color: #000 !important;
  p {
    margin-bottom: 4px;
  }
}
.tippy-tooltip:has(.signOff-popover) .tippy-arrow {
  border-left-color: black !important;
}

.tippy-tooltip:has(.cellReviews-popover) {
  color: white !important;
  font-size: 13px;
  background-color: #000 !important;
  p {
    margin-bottom: 4px;
  }
}
.tippy-tooltip:has(.cellReviews-popover) .tippy-arrow {
  border-top-color: black !important;
}

.wkp-saved-wkp-section {
  &:not(.wkp-empty) {
    .wkp-grid-container {
      .wkp-signOff {
        color: #008510;
        font-weight: 600;

        i {
          margin-right: 5px;
        }
      }

      .wkp-cellReviews {
        border-radius: 14px;
        color: #e61e3c;
        padding: 4px 9px 4px 9px;

        img {
          height: 12px !important;
          margin-bottom: 4px;
        }

        span {
          font-weight: 600;
          font-size: 14px;
          margin-left: 5px;
        }
      }
      .status-color-dot {
        display: inline-block;
        height: 10px;
        width: 10px;
        background-color: #000;
        border-radius: 50%;
        margin-right: 5px;
      }
      .last-modified-container {
        display: flex;
        gap: 10px;
        align-items: center;
        justify-content: flex-start;
        & > p {
          margin: 0;
        }
      }
      .bt-grid-filter-row {
        border-bottom: 1.8px solid black;
      }
      .wkp-grid-actions {
        padding: 8px 25px;
        font-size: 15px;
        display: flex;
        flex-wrap: wrap;

        @media only screen and (max-height: 900px) {
          padding-top: 0px;
          padding-bottom: 5px;
        }
      }

      .wkp-drop-zone {
        position: absolute;
        z-index: 1;
        width: 100%;
        height: 100%;
      }
    }
  }
}
