.wkp-deleted-wkp-container {
  background-color: #ffffff;
  height: calc(100vh - 42px);

  .wkp-tax-period-select-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    height: 100px;
    padding: 20px 20px 0px 0px;
    width: fit-content;
  }
}

.wkp-spinner-wrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
  row-gap: 15px;
  justify-content: center;
  align-items: center;
  height: 100%;
}
