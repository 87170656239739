.wkp-button {
  display: flex;
  align-items: center;
  gap: 10px;

  .wkp-button-icon {
    max-height: 30px;
  }
  .wkp-button-label {
    font-weight: 600;
    font-size: 16px;
    cursor: pointer;
  }
}
