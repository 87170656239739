.wkp-filter-toggle-container {
  display: flex;
  align-items: flex-end;
  margin-left: -8px;
  margin-bottom: 20px;
}

.wkp-fields-to-filter {
  margin-bottom: 10px;
}

.text-formula-area {
  display: grid;
  position: relative;
  width: 100%;
}

.text-formula-area span {
  position: absolute;
  bottom: 10px;
  right: 10px;
}
