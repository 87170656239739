.wkp-recent-activity-item {
  padding: 14px 0;
  border-bottom: 1px solid #eee;

  .wkp-recent-activity-info {
    font-size: 12px;
    color: #666666;
    font-weight: bold;
  }

  .wkp-recent-activity-data {
    font-weight: bold;
    display: flex;
    align-items: center;
    margin-bottom: 4px;

    &:last-child {
      margin: 0;
    }

    .wkp-recent-activity-icon {
      min-width: 15px;
      text-align: center;
      margin-right: 8px;
    }

    .wkp-recent-activity-text {
      overflow: hidden;
      margin-right: 5px;

      .bbui-button {
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
        font-weight: bold;

        img {
          margin-right: 10px;
        }
      }
    }
  }

  &:last-child {
    border: 0;
  }

  &.wkp-recent-activity-loading {
    .wkp-recent-activity-data {
      opacity: 0.5;
    }
  }

  &.wkp-recent-activity-failed {
    .wkp-recent-activity-flow-title,
    .wkp-recent-activity-error-msg,
    .fa-exclamation-circle {
      color: #a94442;
    }
  }
}
