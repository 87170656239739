#wkp-side-bar {
  .wkp-right-pane {
    .wkp-right-pane-wrapper {
      position: relative;
      overflow-y: auto;
      height: 100%;

      .wkp-sign-off-list {
        button {
          margin-left: 0px;
        }
        .wkp-sign-off-delete-icon {
          padding-left: 10px;
          color: #2b8eff;
          cursor: pointer;
        }
        .wkp-sign-off-locked {
          opacity: 0.5;
          cursor: default;
        }
      }

      .wkp-sign-offs {
        margin-top: 20px;
        .wkp-sign-off-empty-state-panel {
          background: white;
          border: 1px solid lightgray;
          display: flex;
          flex-direction: column;
          padding: 20px;
          align-items: center;
        }
      }

      .bbui-feedback {
        position: absolute;
        bottom: 100px;
        width: 100%;
      }
    }
  }
}
