.wkp-data-flow-header {
  padding: 4px 16px;
  display: flex;
  align-items: center;
  background-color: #f5f5f5;
  font-size: 16px;

  &__running {
    margin: 0 1.2rem;
    font-weight: 500;
    display: flex;
    align-items: center;
  }
}

.wkp-data-flow-export-output:disabled {
  &[aria-label] {
    position: relative;
  }

  &[aria-label]:after {
    content: attr(aria-label);
    display: none;
    position: absolute;
    top: -100%;
    left: -100%;
    background-color: #fff;
    text-transform: none;
    color: #333;
    font-weight: lighter;
    font-size: medium;
    padding: 0.2em;
  }

  &[aria-label]:hover::after,
  [aria-label]:focus::after {
    display: block;
  }
}

.wkp-data-flow-tax-period {
  background-color: #e6e6e6;
  border: 1px solid #ccc;
  padding: 0.53em 0.5em;
  border-radius: 0.5em;
}

.mx-3 {
  margin: 0 0.75rem;
}

.bt-alert a {
  text-decoration: underline !important;
}
