#wkp-side-bar {
  .wkp-right-pane {
    .wkp-select-research-topics {
      .wkp-filter-input {
        margin-bottom: 10px;
        position: relative;

        input {
          padding-left: 40px;
        }

        i {
          content: 'X';
          position: absolute;
          left: 15px;
          top: 10px;
          color: #666;
        }
      }

      .wkp-research-topic-list {
        padding: 0 15px;
        background-color: white;
        border: 1px solid #ddd;
        height: calc(100vh - 324px);
        overflow-y: auto;
        margin-bottom: 10px;
      }

      .wkp-select-research-topics-footer {
        .bbui-button {
          width: calc(50% - 5px);
          margin-right: 5px;

          &.primary {
            margin-right: 0;
            margin-left: 5px;
          }
        }
      }
    }

    .wkp-topic-selected {
      height: calc(100vh - 225px);
      overflow-y: auto;

      h2 {
        margin-bottom: 10px;
      }

      h4 {
        margin-top: 20px;
        font-size: 16px;
      }

      .wkp-research-link {
        font-weight: bold;
      }

      .panel-default {
        border: none;
        box-shadow: none;
      }

      .panel-heading {
        background-color: #f9f9f9;
        background-image: none;
        padding: 0;
      }

      .panel-title a {
        text-decoration: none;
        display: block;
        padding: 10px;

        &:hover {
          background-color: #f2f2f2;
        }

        svg {
          min-width: 14px;
          margin-right: 10px;
        }
      }

      .panel-default > .panel-heading + .panel-collapse > .panel-body {
        background-color: #f9f9f9;
        border-top: 0;
      }
    }

    .wkp-right-pane-empty-state {
      .bbui-button {
        width: 100%;
      }
    }
  }
}
