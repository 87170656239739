.wkp-field-to-aggregate-row {
  display: flex;
  margin-bottom: 8px;

  .wkp-field-to-aggregate-field {
    flex: 4;
    margin-right: 1px;
  }

  .wkp-field-to-aggregate-fn {
    flex: 1;
  }
}
