@import '../../../_shared/variables.scss';

.wkp-recycle-bin-button {
  margin-left: auto !important;
  font-weight: 500;

  &:hover {
    color: $btas-crescent-color;
  }
}
.wkp-recycle-bin-button a {
  color: inherit;
  text-decoration: none;
}
.wkp-recycle-bin-button img {
  margin-right: 4px;
  margin-bottom: 2px;
}
