h2.bbui-dialog-title-header {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 0;
}
span.bbui-dialog-title-close-icon {
  margin-top: 20px;
  font-size: 14px;
  color: black;
}

.feedback-bbui-text-area {
  resize: none !important;
  min-height: 150px;
}
textarea.bbui-text-area::placeholder {
  color: #666 !important;
}
button.bt-button-default,
button.bt-button-primary {
  font-weight: bold;
}
button.bt-button-default {
  color: black;
}
.feedbackForm {
  form {
    margin-bottom: 10px;
  }
}
