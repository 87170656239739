.wkp-add-connection-panel {
  overflow-y: auto;

  .wkp-add-footer-buttons {
    margin-top: 20px;

    .col-xs-6 {
      .bbui-button {
        width: 100%;
      }

      &:first-child {
        padding-right: 5px;
      }

      &:last-child {
        padding-left: 5px;
      }
    }
  }
}
