.wkp-add-connection-panel,
.wkp-edit-connection-panel {
  @mixin disabled {
    pointer-events: none;
    opacity: 0.7;
  }

  .wkp-connection-filter {
    margin-top: 20px;
    .bbui-formfield-content {
      .bt-input-wrapper {
        .filter-criteria {
          display: flex;
          align-items: center;
          margin-top: 5px;

          .bt-combobox-wrapper {
            width: 100%;
          }
        }
        .filter-criteria-item {
          width: 100%;
        }
      }

      .trash-can {
        color: #2b8eff;
        padding-left: 10px;
        cursor: pointer;

        &.is-disabled {
          @include disabled;
        }
      }

      .equals {
        font-weight: bold;
        padding: 10px;
      }
    }

    .add-another {
      margin-top: 20px;
      padding: 5px;
      width: fit-content;
      font-weight: 700;
      color: #2b8eff;
      cursor: pointer;

      &.is-disabled {
        @include disabled;
      }
    }
  }
  .wkp-connection-form-error {
    margin-top: 20px;
  }
}
