.wkp-field-to-filter-row {
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  .wkp-field-to-filter {
    flex: 3.2;
    margin-right: 8px;
  }

  .wkp-operator-to-filter {
    flex: 1.5;
    margin-right: 8px;
  }
}

.wkp-value-to-filter-row {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.wkp-value-to-filter-item {
  flex: 4;
  margin-right: 8px;
  max-width: 411px;
}

.wkp-filter-criteria-divider {
  color: #dddddd;
  background-color: #dddddd;
  height: 3px;
  margin-bottom: 16px;
}

.wkp-filter-critieria-ui-last-col {
  width: 25px;
}

.wkp-simple-criteria-block {
  margin-bottom: 10px;
  button.wkp-add-filter-btn {
    margin-left: 0;
  }
}
