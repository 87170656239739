.wkp-data-flow-title,
.wkp-data-flow-title-disabled {
  font-size: 18px !important;
  max-width: 300px;
  background: transparent !important;
  border: 1px solid transparent !important;

  &:focus,
  &:hover {
    border: 1px solid #66afe9 !important;
    background: #fff !important;

    &:not(:focus) {
      background: #fff !important;
      border: 1px solid transparent !important;
      cursor: pointer;
    }
  }
}

.wkp-editable-text-container {
  display: flex;
  flex-direction: column;
  margin: 0 10px;
}

.wkp-data-flow-text {
  visibility: hidden;
  max-width: 300px;
  height: 0;
  max-height: 0;
  font-size: 18px;
  display: inline-block;
  margin-right: 28px;
}

.wkp-data-flow-title-disabled {
  &:hover {
    &:not(:focus) {
      background: none !important;
      pointer-events: none;
    }
  }
}
