@import 'src/components/_shared/variables';

$drop-accepted-border-color: $btas-crescent-color;
$drop-accepted-back-color: rgba(0, 115, 255, 0.08);

.wkp-dropzone {
  border: 2px dashed #000;
  height: 106px;
  text-align: center;
  display: flex;
  align-items: center;

  .wkp-dropzone-area {
    flex: 1 1 0;
  }

  .wkp-dropzone-title {
    font-size: 16px;
    margin-bottom: 14px;

    .wkp-upload-icon {
      margin-right: 0.5em;
      height: 20px;
    }

    .wkp-browse-link {
      cursor: pointer;
      color: #000;
      text-decoration: none;
      border-bottom: 2px dotted #000;
    }
  }

  .wkp-dropzone-formats {
    font-size: 12px;
  }

  .wkp-selected-files {
    img {
      margin-right: 0.2em;
      height: 16px;
    }
  }

  &.wkp-drag {
    border-color: $drop-accepted-border-color;
    background-color: $drop-accepted-back-color;
  }

  &.wkp-selected {
    border: 0;
    background: #eee;
  }
}
