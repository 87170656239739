@import '../variables.scss';

.wkp-tile-button {
  display: inline-block;
  vertical-align: top;
  width: 180px;
  height: 120px;
  background-color: #fff;
  border: 1px solid #eee;
  box-shadow: 1px 2px 4px 0 rgba(0, 0, 0, 0.04);
  cursor: pointer;

  .tile-count {
    display: flex;
    align-items: baseline;
    justify-content: center;

    .count-numerator {
      font-size: 32px;
      font-weight: 600;
    }

    .count-denominator {
      font-size: 14px;
      font-weight: bold;
    }
  }

  img {
    margin-top: 5px;
    margin-bottom: 5px;
    max-height: 49px;
  }

  &:hover,
  &:focus {
    &:not(:disabled) {
      box-shadow: 1px 2px 12px 0 rgba(0, 0, 0, 0.08);
    }
  }

  &:focus {
    outline: 1px dotted $btas-crescent-color;
  }

  &:disabled {
    opacity: 0.6;
  }

  & + .wkp-tile-button {
    margin-left: 30px;
  }
}
