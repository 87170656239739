.newWorkpaperModal {
  .dialog-subtitle {
    margin-top: 20px;
    color: #aeaeae;
  }
  .dialog-title-delimiter {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .bbui-dialog-body button {
    margin: 2px;
  }

  .body-modal-height {
    height: 300px;
  }
}
