.wkp-expression-generator-heading-container {
  margin-top: 10px;
  img {
    background-color: #267ac3;
    padding: 4px;
    float: left;
    margin: 0px 5px 10px 0px;
    width: 10%;
    height: auto;
  }
  label {
    padding: 2px;
    float: left;
  }
  a {
    padding: 4px;
    float: left;
  }
}
.wkp-expression-generator-example {
  background-color: #eee;
  padding: 12px 15px;
  font-size: 14px;
}
.wkp-expesion-generator-ai-lab {
  text-align: center;
  margin-top: 10px;
  .expression-generator-summary-disclaimer {
    font-size: 12px;
    font-style: italic;
    margin: 10px 0;
  }
  img {
    background-color: #267ac3;
    padding: 4px;
    float: left;
    margin: 0px 5px 0px 0px;
    width: 10%;
    height: auto;
  }
  p {
    padding-top: 2px;
    text-align: justify;
  }
}

.text-error {
  color: #e61e3c;
}
