.rollforward-modal {
  .body-modal-height {
    height: 300px;
    position: relative;
  }

  button {
    margin: 2px;
  }

  .rollforward-summary-alert {
    position: absolute;
    bottom: 0;
  }
}

.bbui-dialog-content-container {
  max-width: 750px !important;
  width: 750px !important;
}

.bbui-dialog-body {
  overflow-x: hidden;
}
