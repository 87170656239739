.wkp-deleted-wkp-section {
  &:not(.wkp-empty) {
    .wkp-grid-container {
      h2 {
        padding: 8px 25px;
        font-weight: 500;
        font-size: 24px;
      }

      .deleted-on {
        display: flex;
        gap: 10px;
        align-items: center;
        justify-content: flex-start;
        & > p {
          margin: 0;
        }
      }
      .bt-grid-filter-row {
        border-bottom: 1.8px solid black;
      }
      .wkp-grid-actions {
        padding: 8px 25px;
        font-size: 15px;
        display: flex;
        flex-wrap: wrap;
        cursor: default;

        @media only screen and (max-height: 900px) {
          padding-top: 0px;
          padding-bottom: 5px;
        }
      }
    }
  }
}
