.wkp-view-source-data-modal-xl {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 20px;

  .bbui-overlay-container {
    height: 100%;
    width: 100%;

    .bbui-dialog-content-container {
      max-width: none;
    }

    .bbui-dialog-body {
      max-height: none;
      overflow-y: unset;

      .wkp-source-data-hint {
        margin-top: 20px;
        padding: 0 50px;
      }

      .wj-flexgrid {
        height: 65vh;

        @media only screen and (max-height: 900px) {
          height: 50vh;
        }
      }

      .wkp-header-text {
        text-align: right;

        span {
          margin-right: 2em;

          &:first-child {
            margin-right: 4em;
          }
        }
      }
    }
  }
}
