#rootFooter {
  position: fixed;
  width: 100%;
  bottom: 0;
  background: #fff;
  z-index: 5;

  .bbna-footer {
    margin-top: 0 !important;
    color: #ccc;
    padding-top: 5px;
    margin-top: 50px;
    border-top: 1px solid #ddd;
    font-size: 12px;
    background-color: #555 !important;
    bottom: 0;
    border: 0;
    width: 100%;

    .list-inline {
      margin: 0 0 0 5px;

      li {
        padding: 0;
        display: inline-block;
        list-style: none;
      }

      li::before {
        content: '';
      }

      li::after {
        content: '|';
        padding: 0 5px;
      }
    }

    p {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-bottom: 5px;
    }

    a,
    a:hover,
    a:focus {
      color: #fff;
    }
  }
}
