.react-autocomplete-input {
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  margin-top: 10px;
  bottom: auto;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  display: block;
  font-size: 14px;
  list-style: none;
  padding: 1px;
  position: absolute;
  text-align: left;
  z-index: 20000;
  overflow: auto;
  max-height: 350px;
  max-width: 200px;
  @media only screen and (max-height: 900px) {
    max-height: 200px;
  }
  li {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.react-autocomplete-input > li {
  cursor: pointer;
  padding: 10px;
  min-width: 100px;
  font-size: 12px;
}

.react-autocomplete-input > li.active {
  background-color: #bbbbbb;
  color: #fff;
}

.react-autocomplete-dialog-Helper {
  font-family: arial, sans-serif;
  background-clip: padding-box;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  margin-top: 10px;
  bottom: auto;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  display: block;
  font-size: 14px;
  list-style: none;
  padding: 1px;
  position: absolute;
  text-align: left;
  z-index: 20000;
  max-width: 450px;
  .header {
    background-color: #ebe8e8;
    font-size: 13px;
    padding: 5px;
    font-family: inconsolata, monospace, arial, sans, sans-serif;
    .params {
      display: inline;
      font-style: italic;
    }
  }
  .title {
    font-size: 11px;
    margin-top: 5px;
    margin-left: 5px;
    color: #222;
    font-weight: bold;
  }
  .description {
    font-size: 11px;
    margin-top: 5px;
    margin-left: 5px;
    margin-right: 5px;
    color: #222;
  }
  .parameter-active {
    background-color: #feb;
  }
  table {
    font-size: 11px;
    color: #222;
    margin-left: 5px;
    margin-right: 5px;
    margin-top: 10px;
    margin-bottom: 5px;
    border-collapse: collapse;

    tr {
      border-bottom: 2px solid #ebe8e8;
    }

    tr:last-child {
      border: 0;
    }

    td {
      font-style: italic;
      font-weight: bold;
      padding: 5px 5px 5px 5px;
    }

    td + td {
      padding-left: 5px;
      font-weight: normal;
      font-style: normal;
    }
  }
  h5 {
    font-size: 12px !important;
    font-weight: bold !important;
    color: #222 !important;
    border: 0 !important;
    margin-left: 5px;
    margin-top: 5px;
    margin-bottom: 5px;
    padding: 0 !important;
  }
  pre {
    padding: 5px;
    margin: 0 5px 10px 5px;
    font-size: 11px;
    white-space: pre-wrap;
    color: #171a1e;
    background-color: #c7e3ff;
    border-color: #c7e3ff;
  }
  .full-description {
    font-size: 11px;
    margin-top: 5px;
    margin-left: 5px;
    color: #222;
  }
}
