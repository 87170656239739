.saveAs {
  overflow-y: visible !important;

  .notification {
    background-color: rgb(240, 240, 240);
    padding: 10px;

    p {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      span {
        padding: 0 10px;
      }
    }
  }
}
